// ** basic ui **
import './modules/basic-ui/core.js';

// polyfills
import './modules/basic-ui/polyfills/find.js';
import './modules/basic-ui/polyfills/array.from.js';
import './modules/basic-ui/polyfills/closest.js';
import './modules/basic-ui/polyfills/number.parseInt.js';
import './modules/basic-ui/polyfills/startsWith.js';
import './modules/basic-ui/polyfills/customEvent.js';

// ui
import { BasicPopup } from './modules/basic-ui/ui/popup.js';
// ** /basic ui **


// others
import './modules/methods.js';
import { CoreSite } from './modules/core.js';



// init
document.addEventListener('DOMContentLoaded', () => {
	
    BasicPopup.init();
	CoreSite.init();

});