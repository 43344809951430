/**
 * Main core libs
 * @namespaces
 * @property {function} MainMethod.variables — build variables;
 * @property {object}   MainMethod.logging   — message logging.
 */
const MainMethod = {

    variables: (variable, defaultClass) => {

        let result = null;
        
        if (variable == undefined) 		  result = Array.from(document.querySelectorAll(defaultClass));
        if (typeof variable == 'string')  result = [document.querySelector(variable)] || false;	
        if (typeof variable == 'object')  result = (variable.length !== undefined) ? Array.from(variable) : [variable] || false
    
        return result;
    
    },

    logging: {
        'name': '#BasicUI',
        'error': {
            'missing': `\n *Eng: "Element missing"\n *Rus: "Отсутствует элемент"`,    
            'type': `\n *Eng: "Invalid input value. Accepts values of type String or Object"\n *Rus: "Неправильное входное значение. Принимает значения типа String или Object"`,        
            'popup': {
                'missing': {
                    'modal':   `\n *Eng: "Missing popup element"\n *Rus: "Отсутствует попап элемент"`,
                    'trigger': `\n *Eng: "Missing trigger element"\n *Rus: "Отсутствует триггер элемент"`
                },
            }
        }
    }
}


window.MainMethod = MainMethod;


export { MainMethod };