(function () {
'use strict';

/**
 * Main core libs
 * @namespaces
 * @property {function} BasicCore.variables — build variables;
 * @property {object}   BasicCore.logging   — message logging.
 */
const BasicCore$1 = {

    variables: (variable, defaultClass) => {

        let result = null;
        
        if (variable == undefined) 		  result = Array.from(document.querySelectorAll(defaultClass));
        if (typeof variable == 'string')  result = [document.querySelector(variable)] || false;	
        if (typeof variable == 'object')  result = (variable.length !== undefined) ? Array.from(variable) : [variable] || false;
    
        return result;
    
    },

    logging: {
        'name': '#BasicUI',
        'error': {
            'missing': `\n *Eng: "Element missing"\n *Rus: "Отсутствует элемент"`,    
            'type': `\n *Eng: "Invalid input value. Accepts values of type String or Object"\n *Rus: "Неправильное входное значение. Принимает значения типа String или Object"`,        
            'popup': {
                'missing': {
                    'modal':   `\n *Eng: "Missing popup element"\n *Rus: "Отсутствует попап элемент"`,
                    'trigger': `\n *Eng: "Missing trigger element"\n *Rus: "Отсутствует триггер элемент"`
                },
            }
        }
    }
};


window.BasicCore = BasicCore$1;

if (!Array.prototype.find) {
    
    Object.defineProperty(Array.prototype, 'find', {
        
        value: function (predicate) {
            
            // 1. Let O be ? ToObject(this value).
            if (this == null) {

                throw new TypeError('"this" is null or not defined');

            }

            var o = Object(this);            
            var len = o.length >>> 0; // 2. Let len be ? ToLength(? Get(O, "length")).
            
            // 3. If IsCallable(predicate) is false, throw a TypeError exception.
            if (typeof predicate !== 'function') {
                
                throw new TypeError('predicate must be a function');
            
            }

            // 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
            var thisArg = arguments[1];            
            var k = 0; // 5. Let k be 0.

            // 6. Repeat, while k < len
            while (k < len) {

                var kValue = o[k];
                
                if (predicate.call(thisArg, kValue, k, o)) {
                
                    return kValue;
                
                }
                
                // e. Increase k by 1.
                k++;
            
            }

            // 7. Return undefined.
            return undefined;
        },

        configurable: true,
        writable: true

    });

}

// Array.from()
if (!Array.from) {

    Array.from = function(object) {

        'use strict';
        return [].slice.call(object);

    };

}

// Element.closest()
if (!Element.prototype.closest) {
	
	Element.prototype.closest = function(css) {
		
		let node = this,
			result,
			matches = node.matches || node.matchesSelector || node.webkitMatchesSelector || node.mozMatchesSelector || node.msMatchesSelector;	
		

		// polyfill matches					
		if (!matches) {
		
			(node.matches = node.matchesSelector = function matches(selector) {
		        
		        var matches = document.querySelectorAll(selector),
		        	th = this;
		        
		        return Array.prototype.some.call(matches, function(e) {
		        
		            return e === th;
		        
		        });
		    
		    });
		
		} else {
			
			node.matches = node.matchesSelector = matches;
		
		}


		result = node.matches(css);


		while (node) {
		
			if (result){
				
				return node;	
			
			} else {

				node = node.parentElement;

			}	 
	   	
	   	} 
	   	
	   	return null; 
		
	};

}

if (Number.parseInt === undefined) Number.parseInt = window.parseInt;

if (!String.prototype.startsWith) {
    
    Object.defineProperty(String.prototype, 'startsWith', {
        enumerable: false,
        configurable: false,
        writable: false,
        value: function(searchString, position) {
            position = position || 0;
            return this.indexOf(searchString, position) === position;
        }
    });

}

(function() {

    if (typeof window.CustomEvent === "function") return false;


    function CustomEvent(event, params) {
        
        params = params || { bubbles: false, cancelable: false, detail: null };
        
        var evt = document.createEvent('CustomEvent');        
        evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
        
        return evt;
    
    }


    window.CustomEvent = CustomEvent;

})();

/**
 * Basic UI: Popup.
 */
const BasicPopup = (() => {

	class Core {

	    constructor({trigger, popup, content}) {
			
			this.$trigger = trigger || null;
			
			this.$popup = {
				el: 	 popup   || null,
				content: content || ''
			};		

			this.$options = {
				className: {
					main:  	 'popup',
					show: 	 'popup--show',
					close: 	 'popup-close',
					content: 'popup-content'
				}
			};

			this.$customEventVisibility = new CustomEvent('popup visibility', { 
				bubbles: true,
				detail:  {
					'popup': null,
					'trigger': null,
					'show': false
				}
			});
			
			
			this.build();
			this.events();
	    	
	    }

		
		build() {

			let popupModal = null;


	    	if (this.$popup.el !== null && this.$popup.el.classList.contains('popup-init') == false) {
				
				popupModal = document.createElement('div');
				popupModal.classList.add('popup-modal');
				popupModal.setAttribute('role', 'dialog');
				popupModal.setAttribute('aria-modal', 'true');
				popupModal.innerHTML = `
					<button class="${this.$options.className.close}" type="button" title="Close popup" aria-label="Close popup" data-popup-close>
						<svg width="512" height="512" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
							<path fill-rule="evenodd" clip-rule="evenodd" d="M5.35576 5.35576C12.4968 -1.78525 24.0747 -1.78525 31.2157 5.35576L256 230.14L480.784 5.35576C487.925 -1.78525 499.503 -1.78525 506.644 5.35576C513.785 12.4968 513.785 24.0747 506.644 31.2157L281.86 256L506.644 480.784C513.785 487.925 513.785 499.503 506.644 506.644C499.503 513.785 487.925 513.785 480.784 506.644L256 281.86L31.2157 506.644C24.0747 513.785 12.4968 513.785 5.35576 506.644C-1.78525 499.503 -1.78525 487.925 5.35576 480.784L230.14 256L5.35576 31.2157C-1.78525 24.0747 -1.78525 12.4968 5.35576 5.35576Z"/>
						</svg>
					</button>
					<div class="${this.$options.className.content}"></div>
				`;
				
				while (this.$popup.el.firstChild) {

					popupModal.querySelector(`.${this.$options.className.content}`).appendChild(this.$popup.el.firstChild);

				}
				
				this.$popup.el.classList.add(this.$options.className.main);
				this.$popup.el.classList.add('popup-init');
				this.$popup.el.appendChild(popupModal);

	    	}
		
	    }


		events() {

			if (this.$trigger !== null)  this.$trigger.addEventListener('click', this.listener_trigger.bind(this), false);
			if (this.$popup.el !== null) this.$popup.el.addEventListener('click', this.listener_popup.bind(this), false);

		}


		listener_trigger() {

			this.method_show();

	    	// custom event: popup toggle
			this.$customEventVisibility.detail.show = true;
			this.$customEventVisibility.detail.trigger = this.$trigger;
			this.$customEventVisibility.detail.popup = this.$popup.el;
			this.$popup.el.dispatchEvent(this.$customEventVisibility);	

	    }

	
		listener_popup(event) {

	    	let target = event.target;
			

			if (target.classList.contains(this.$options.className.main) == true || target.getAttribute('data-popup-close') !== null) {

				this.method_hide();				

			}

	    	// custom event: popup toggle
			this.$customEventVisibility.detail.show = false;
			this.$customEventVisibility.detail.popup = this.$popup.el;
			this.$customEventVisibility.detail.trigger = this.$trigger;
			this.$popup.el.dispatchEvent(this.$customEventVisibility);	

	    }


		method_show() {
						
			document.documentElement.classList.add('html-popup');
	    	this.$popup.el.classList.add(this.$options.className.show);

	    }


	    method_hide() {

			document.documentElement.classList.remove('html-popup');
			this.$popup.el.classList.remove(this.$options.className.show);

	    }


		method_html() {

			this.$popup.el.querySelector(`.${this.$options.className.content}`).innerHTML = this.$popup.content;
			this.method_show();

		}

	}


	let active = null;


	/**
	 * Popup initialization
	 * @param {(Object|string)} config.trigger - trigger element
	 * @param {string} 			config.popup   - popup element
	 */
	const init = (config) => {

		let options = config || {},
			elements = BasicCore.variables(options.trigger, '.js-trigger-popup');


		try {

			if (elements == false && options.trigger !== undefined) throw new Error(BasicCore.logging['error']['popup']['missing']['trigger']);
			if (elements == null  && options.trigger !== undefined) throw new Error(BasicCore.logging['error']['type']);			
							
			elements.forEach((variable) => {

				options.trigger = variable;
				options.popup = document.querySelector(`#${variable.getAttribute('data-popup-id')}`) || document.querySelector(options.popup);
				
				active = new Core(options);
			
			});
			
			return active;
		
		} catch(error) {

			console.error(`${BasicCore.logging['name']} Popup init. \nMessage: ${error.message} \nElement: `, options.trigger);

		}

	};


	/**
	 * Method: Show a popup
	 * @param {(Object|string)} popup - show a popup element
	 */
	const show = (popup) => {

		let options = {
			popup: (typeof popup == 'object') ? popup : document.querySelector(popup)
		};
				

		try {

			if (options.popup == null) throw new Error(BasicCore.logging['error']['popup']['missing']['modal']);
			
			active = new Core(options);
			active.method_show();
			
			return active;
		
		} catch(error) {

			console.error(`${BasicCore.logging['name']} Popup show. \nMessage: ${error.message} \nElement:`, popup);

		}	

	};	


	/**
	 * Method: Hide a popup
	 * @param {(Object|string)} popup - hide a popup element
	 */
	const hide = (popup) => {

		let options = {
			popup: (typeof popup == 'object') ? popup : document.querySelector(popup)
		};	

		
		try {

			if (options.popup == null) throw new Error(BasicCore.logging['error']['popup']['missing']['modal']);
			
			active = new Core(options);
			active.method_hide();

			return active;
		
		} catch(error) {

			console.error(`${BasicCore.logging['name']} Popup hide. \nMessage: ${error.message} \nElement:`, popup);

		}	

	};


	/**
	 * Method: add dynamic content in popup
	 * @param {(Object|string)} popup   - popup element
	 * @param {(String)} 		content - popup content
	 */
	const html = (config) => {

		let options = config || {};	

		options.popup = (typeof options.popup == 'object') ? options.popup : document.querySelector(options.popup);
		
		try {

			if (options.popup == null) throw new Error(BasicCore.logging['error']['popup']['missing']['modal']);
			
			active = new Core(options);
			active.method_html();

			return active;
		
		} catch(error) {

			console.error(`${BasicCore.logging['name']} Popup html. \nMessage: ${error.message} \nElement:`, config.popup);
		
		}	

	};


	return { init, show, hide, html };

})();
	

window.BasicPopup = BasicPopup;

/**
 * Main core libs
 * @namespaces
 * @property {function} MainMethod.variables — build variables;
 * @property {object}   MainMethod.logging   — message logging.
 */
const MainMethod = {

    variables: (variable, defaultClass) => {

        let result = null;
        
        if (variable == undefined) 		  result = Array.from(document.querySelectorAll(defaultClass));
        if (typeof variable == 'string')  result = [document.querySelector(variable)] || false;	
        if (typeof variable == 'object')  result = (variable.length !== undefined) ? Array.from(variable) : [variable] || false;
    
        return result;
    
    },

    logging: {
        'name': '#BasicUI',
        'error': {
            'missing': `\n *Eng: "Element missing"\n *Rus: "Отсутствует элемент"`,    
            'type': `\n *Eng: "Invalid input value. Accepts values of type String or Object"\n *Rus: "Неправильное входное значение. Принимает значения типа String или Object"`,        
            'popup': {
                'missing': {
                    'modal':   `\n *Eng: "Missing popup element"\n *Rus: "Отсутствует попап элемент"`,
                    'trigger': `\n *Eng: "Missing trigger element"\n *Rus: "Отсутствует триггер элемент"`
                },
            }
        }
    }
};


window.MainMethod = MainMethod;

const CoreSite = (() => {

    class Core {

        constructor() {

            this.logotype          = document.querySelector('.logotype') || false;
            this.triggerDarkTheme  = document.querySelector('.dark-theme');
            this.storageColorSheme = localStorage.getItem('color-scheme') || 'dark';
            

            this.build();
            this.events();

        }


        build() {

            if (this.logotype !== false) {

                this.random_color();

            }


            if (this.storageColorSheme == 'dark') {

				this.triggerDarkTheme.querySelector('input').setAttribute('checked', true);				
				
			} else {
				
				this.triggerDarkTheme.querySelector('input').removeAttribute('checked');				

			}

        }


        events() {

            this.triggerDarkTheme.addEventListener('click', this.listener_dark_theme.bind(this), false);

        }


        random_color() {

            let MainRandom = () => (Math.random().toString(16) + '000000').substring(2,8).toUpperCase();
            
		    this.logotype.setAttribute(
                'style',                
                `--color-logotype: linear-gradient(45deg, 
                    #${MainRandom()} 0%, 
                    #${MainRandom()} 100%);`
            );
        
        }


        listener_dark_theme(event) {

			let target = event.target,
                result = (target.checked == true) ? 'dark' : 'light';

			
            localStorage.setItem('color-scheme', result);
            document.documentElement.setAttribute('data-color-scheme', result);
            document.querySelector('meta[name="color-scheme"]').setAttribute('content', result);

		}

    }


    let active = null;


    const init = () => {

        return active = new Core();

    };


    return { init }

})();


window.CoreSite = CoreSite;

// ** basic ui **
// polyfills
// ui
// ** /basic ui **


// others
// init
document.addEventListener('DOMContentLoaded', () => {
	
    BasicPopup.init();
	CoreSite.init();

});

}());
