const CoreSite = (() => {

    class Core {

        constructor() {

            this.logotype          = document.querySelector('.logotype') || false;
            this.triggerDarkTheme  = document.querySelector('.dark-theme');
            this.storageColorSheme = localStorage.getItem('color-scheme') || 'dark';
            

            this.build();
            this.events();

        }


        build() {

            if (this.logotype !== false) {

                this.random_color();

            }


            if (this.storageColorSheme == 'dark') {

				this.triggerDarkTheme.querySelector('input').setAttribute('checked', true);				
				
			} else {
				
				this.triggerDarkTheme.querySelector('input').removeAttribute('checked');				

			}

        }


        events() {

            this.triggerDarkTheme.addEventListener('click', this.listener_dark_theme.bind(this), false);

        }


        random_color() {

            let MainRandom = () => (Math.random().toString(16) + '000000').substring(2,8).toUpperCase();
            
		    this.logotype.setAttribute(
                'style',                
                `--color-logotype: linear-gradient(45deg, 
                    #${MainRandom()} 0%, 
                    #${MainRandom()} 100%);`
            );
        
        }


        listener_dark_theme(event) {

			let target = event.target,
                result = (target.checked == true) ? 'dark' : 'light';

			
            localStorage.setItem('color-scheme', result);
            document.documentElement.setAttribute('data-color-scheme', result);
            document.querySelector('meta[name="color-scheme"]').setAttribute('content', result);

		}

    }


    let active = null;


    const init = () => {

        return active = new Core();

    };


    return { init }

})()


window.CoreSite = CoreSite;


export { CoreSite }